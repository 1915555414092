import {AxiosError} from 'axios';
import {equals, is, reduce, test, toPairs} from 'ramda';

export function pairsToFormData(pairs: [key: string, value: any][]) {
  return reduce(
    (prev, curr) => {
      const [key, value] = curr;
      prev.append(key, is(File, value) ? value : String(value));
      return prev;
    },
    new FormData(),
    pairs
  );
}

export function mapFormData(values: Record<any, any>) {
  return pairsToFormData(toPairs(values));
}

export function isClientBrowser() {
  return (
    !equals(typeof window, 'undefined') &&
    window.document &&
    window.document.createElement
  );
}

export function handleServerApiError(error: AxiosError) {
  console.error(error);
  if (error.response) {
    return error.response;
  } else if (error.request) {
    return {data: {}, status: 500, statusText: 'Internal Server Error'};
  } else {
    return {data: {}, status: 500, statusText: 'Internal Server Error'};
  }
}

export function isAppUserAgent() {
  return (
    isClientBrowser() &&
    test(/(KakaoentPartnerApp)\/((\d+.)+.\d+)/, window.navigator.userAgent)
  );
}
