import {isNilEmpty, prop} from '@kakaoent/ops-design';
import {unstable_noStore as noStore} from 'next/cache';
import {decryptAES} from './crypto';
import {isClientBrowser} from './network';

let clientEnvVariables: Record<string, string>;

/**
 * @template E Interface of EnvVariables
 */
export function getEnv(key: string) {
  if (isClientBrowser()) {
    if (!clientEnvVariables) {
      setClientEnvVariables();
    }

    const decryptValue = clientEnvVariables?.[key];
    return decryptValue;
  }

  noStore();
  return prop(key, process.env);
}

function setClientEnvVariables() {
  const decrypedString = decryptAES(window['__ENV'] ?? '');
  if (isNilEmpty(decrypedString)) {
    return;
  }
  clientEnvVariables = JSON.parse(decrypedString);
}
