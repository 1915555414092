import cryptoAes from 'crypto-js/aes';
import cryptoEncUtf8 from 'crypto-js/enc-utf8';
import {JSEncrypt} from 'jsencrypt';
import {isNil, prop} from 'ramda';
import AesKeyJson from '../.secret/aes_key.json';
import {isClientBrowser} from './network';

export function encryptAES(target: string) {
  return cryptoAes.encrypt(target, AesKeyJson.key).toString().replace('=', '');
}

export function decryptAES(encryptedString: string) {
  return cryptoAes
    .decrypt(encryptedString, AesKeyJson.key)
    .toString(cryptoEncUtf8);
}

export function assocEncryptAES<T extends object>(
  keys: string[],
  target: T
): T {
  return {
    ...target,
    ...keys.reduce((acc, key) => {
      const value = prop(key as keyof T, target);
      if (isNil(value)) {
        return acc;
      }

      return {
        ...acc,
        [key]: encryptAES(String(value)),
      };
    }, {}),
  } as T;
}

export function assocDecryptAES<T extends object>(
  keys: string[],
  target: T
): T {
  return {
    ...target,
    ...keys.reduce((acc, key) => {
      const value = prop(key as keyof T, target);
      if (isNil(value)) {
        return acc;
      }

      return {
        ...acc,
        [key]: decryptAES(String(value)),
      };
    }, {}),
  } as T;
}

/**
 * RSA PublicKey 암호화 함수
 * publicKey 파일은 빌드시 fyriRoot/packages/webapp/.bin/gen-rsa-keypair.mjs를 통해 생성
 *
 * @param string
 * @returns encrypted string
 */
export function encryptRSA(publicKey: string, text: string) {
  const jsencrypt = new JSEncrypt();
  jsencrypt.setPublicKey(publicKey);
  return jsencrypt.encrypt(text);
}

/**
 * RSA PrivateKey 복호화 함수
 * privateKey 파일은 빌드시 ${fyriRoot}/packages/webapp/.bin/gen-rsa-keypair.mjs를 통해 생성
 *
 * @param encryptedPassword
 * @returns
 */
export function decryptRSA(privateKey: string, encryptedText: string) {
  if (isClientBrowser()) {
    throw new Error('RSA 복호화는 서버환경에서만 사용할 수 있습니다.');
  }

  const jsencrypt = new JSEncrypt();
  jsencrypt.setPrivateKey(privateKey);
  return jsencrypt.decrypt(encryptedText) || '';
}
