;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"SNLxHEiFcmFDfdphwM9g8"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "";import {isNilEmpty} from '@kakaoent/ops-design';
import * as Sentry from '@sentry/nextjs';
import {getEnv} from './utils/getEnv';
import {waitPromise} from './utils/waitPromise';

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

/**
 * 클라이언트 환경에서 window 객체에 환경변수가 세팅되는데 레이턴시가 발생할 수 있어서
 * 환경변수가 세팅될 때까지 대기했다가 Sentry 초기화
 */
const RETRY_COUNT = 5;

async function getSentryEnvironments(
  retryCount = 0
): Promise<{NODE_ENV: string; SENTRY_DSN: string; APP_VERSION: string}> {
  try {
    const NODE_ENV = getEnv('NODE_ENV');
    const SENTRY_DSN = getEnv('SENTRY_DSN');
    const APP_VERSION = getEnv('APP_VERSION');

    if (
      isNilEmpty(NODE_ENV) ||
      isNilEmpty(SENTRY_DSN) ||
      isNilEmpty(APP_VERSION)
    ) {
      throw new Error('Sentry environments are not set');
    }

    return {NODE_ENV, SENTRY_DSN, APP_VERSION};
  } catch (error) {
    if (retryCount < RETRY_COUNT) {
      await waitPromise(500);
      return getSentryEnvironments(retryCount + 1);
    }
    throw error;
  }
}

// Init Sentry
async function initSentry() {
  const {NODE_ENV, SENTRY_DSN, APP_VERSION} = await getSentryEnvironments();

  Sentry.init({
    enabled: NODE_ENV === 'production',
    dsn: SENTRY_DSN,
    // tracesSampleRate: 1.0,
    ignoreErrors: ['UnhandledRejection', 'Non-Error promise rejection'],
    release: APP_VERSION,
    integrations: [Sentry.extraErrorDataIntegration()],

    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
  Sentry.setTag('side', 'client');
}
initSentry();
